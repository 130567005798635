
import { defineComponent } from "vue";
import PageHeader from "@/common/PageHeader.vue";
import { paginatorService } from "@/services/paginator.service";
import { contentsService, StaticContent } from "@/services/contents.service";

export default defineComponent({
  name: "TeachingMaterials",
  components: {PageHeader},
  data() {
    return {
      page: undefined as StaticContent | undefined,
      list: [] as any[] | undefined,
      toggle: {},
    };
  },
  mounted() {
    contentsService.findBySlug("materials").then((page) => {
      this.page = page as StaticContent;
    });
    paginatorService.load('materials', 'asc').then((list) => {
      this.list = list.map(item => {
        item.attachments.forEach((attach: any) => {
          const parts = attach.file.path.split('.');
          attach.ext = parts[parts.length - 1];
          const fnParts = attach.file.path.split('/');
          attach.fileName = fnParts[fnParts.length - 1];
        });
        return item;
      });
    });
  }
});
